import { Map } from '../../../types/mapper/maps';

// Temporary mocked Map - we'll bring this from the API.
export const tempMap: Map = {
    id: 'blank',
    label: 'Example map',
    description: '',
    status: 'active',
    data: {
      dimensionality: '3d',
    },
    exportedMapId: 'hilti', //This will be loaded from 'public/pointclouds/[folder]/'
    affordances: [],
    annotations: [],
    sessions: [],
    createdTimestamp: '',
    lastUpdatedTimestamp: '',
    userRoles: [],
  };

export const tempSession = {
    
}