import { useApolloClient } from '@apollo/client';
import React from 'react';
import { tempMap as mockedMap } from '../../components/mapper/visualization/mocks';
import PotreeControl from '../../components/mapper/visualization/PotreeControl';

export default function PotreeSandboxPage(){
  const navabilityClient = useApolloClient();

  return (
    <PotreeControl map={mockedMap} navabilityClient={navabilityClient}></PotreeControl>
  )
    // useEffect(() => {
    //     if(typeof window !== `undefined`) {
    //         if(location && location.state && location.state.prevPath) {
    //             window.localStorage.setItem('prevPath', location.state.prevPath);
    //         }
    //         window.location.replace(loginUrl)
    //     }
    // },[])
    // return null
}
